import { Component, OnInit, Input, Output } from '@angular/core';
import { WizardService } from '../../../../service/wizard/wizard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { CadastroService } from '../../../../service/cadastro/cadastro.service';
import moment from 'moment-timezone';

import { getSwal } from '../../../../utils/swalObject';
import swal from 'sweetalert2';

@Component({
  selector: '[app-resumo]',
  templateUrl: 'resumo.component.html',
  styleUrls: ['./resumo.component.scss'],
})
export class ResumoComponent implements OnInit {
  routeSub: Subscription;
  perfil: string = '';
  index: number = 4;
  data = {};
  infoBasica = null;
  repLegal = null;
  procurador = null;
  prefeituras = null;
  tipo = null;
  semOutorga: boolean = false;
  loading: boolean = false;
  id: number = null;
  pessoa: any;
  atribuir = false;
  termoAdesao: any; //
  exigeTermoAdesao: boolean = false;
  liConcordo: boolean = false;
  dataCurta: any = moment(new Date()).format('DD/MM/YYYY'); 
  dataLonga: any = moment().format('LL');
  cpfRepresentanteLegal: any;
  nomeRepresentanteLegal: string
  
  constructor(
    private wizardService: WizardService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private cadastroService: CadastroService,
  ) {

    this.wizardService.updateIndex(this.index);
  }

  ngOnInit() {
    if (this.wizardService.userData['perfil']=='') {
      this.index = this.index - 1;
      this.wizardService.updateIndex(this.index);
      this.location.back();
    }

    this.perfil = this.wizardService.userData['perfil'];
    this.prefeituras = this.wizardService.userData.prefeituras;

    if (this.router.url.split('/')[3] === 'resumo-atribuir-contribuinte') {
      this.atribuir = true;
    }
    console.log('this.wizardService.:', this.wizardService)

    if (!this.wizardService.userData.representanteLegal['cpfRepresentanteLegal']){
      this.cpfRepresentanteLegal = "Não informado"
      this.nomeRepresentanteLegal = "Não informado"
    }else{
      this.cpfRepresentanteLegal = this.wizardService.userData.representanteLegal['cpfRepresentanteLegal']
      this.nomeRepresentanteLegal = this.wizardService.userData.representanteLegal['nomeRepresentanteLegal']
    }
    console.log(this.cpfRepresentanteLegal)
    this.cadastroService.clientes().subscribe(
      res => {
        this.termoAdesao = res.resultado[0].configuracao.termoAdesao.texto
          .replace(/@{id do contribuinte}/g, this.wizardService.userData.infoBasica['cnpjContribuinte'])
          .replace(/@{contribuinte}/g, this.wizardService.userData.infoBasica['razaoSocialContribuinte'])
          .replace(/@{id do representante}/g, this.cpfRepresentanteLegal)
          .replace(/@{representante}/g, this.nomeRepresentanteLegal)
          .replace(/@{data curta}/g, this.dataCurta)
          .replace(/@{data longa}/g, this.dataLonga)
        this.exigeTermoAdesao = res.resultado[0].configuracao.exigeTermoAdesao
        console.log("exige termo: ", this.exigeTermoAdesao)
      }
    )
  }
  // contribuiteTermo(event){
  //   console.log('event fora:', event)
  //   this.termoAdesao = event
  // }

  proximo() {
    this.loading = true;
    this.index = 5;
    this.wizardService.updateIndex(this.index);
    let data = this.wizardService.userData;
    
    let clientes: any;
    let prefeituras = this.prefeituras.filter(elem => elem.selected === true);
    prefeituras.forEach(element => delete element['selected']);
    this.perfil.toLowerCase() === 'contribuinte'
      ? (clientes = prefeituras[0])
      : (clientes = prefeituras);
    let obj = {
      ...data.infoBasica,
      ...data.procurador,
      ...data.representanteLegal,
    };

    if(obj['cpfRepresentanteLegal']){
      obj['cpfRepresentanteLegal'] = obj['cpfRepresentanteLegal'].replace(/\D/g, '');
    }
    if(obj['cpfCnpjProcurador']){
      obj['cpfCnpjProcurador'] = obj['cpfCnpjProcurador'].replace(/\D/g, '');
    }
    if(obj['telefoneCelular']){
      obj['telefoneCelular'] = obj['telefoneCelular'].replace("-", '');
    }

    this.pessoa = obj.pessoa;

    if(this.pessoa == 'fisica'){
      if(this.perfil == 'contribuinte'){
        sessionStorage.setItem('cpf', this.wizardService.userData.infoBasica['cnpjContribuinte']);
      }else{
        sessionStorage.setItem('cpf', this.wizardService.userData.infoBasica['cpfCnpjProcurador']);
      }
      sessionStorage.setItem('dddTelefoneCelular', this.wizardService.userData.infoBasica['dddTelefoneCelular']);
      sessionStorage.setItem('telefoneCelular', this.wizardService.userData.infoBasica['telefoneCelular']);
      sessionStorage.setItem('email', this.wizardService.userData.infoBasica['email']);
    }else{
      sessionStorage.setItem('dddTelefoneCelular', this.wizardService.userData.representanteLegal['dddCelularRepresentanteLegal']);
      sessionStorage.setItem('telefoneCelular', this.wizardService.userData.representanteLegal['celularRepresentanteLegal']);
      sessionStorage.setItem('email', this.wizardService.userData.representanteLegal['emailRepresentanteLegal']);
      sessionStorage.setItem('cpf', this.wizardService.userData.representanteLegal['cpfRepresentanteLegal']);
    }

    console.log('this.wizardService.:', this.wizardService)
    if (this.perfil.toLowerCase() === 'contribuinte') {
      obj['cliente'] = clientes;
      this.id = this.wizardService.userData.infoBasica['cnpjContribuinte'];
      this.cadastroService
        .cadastrarContribuinte(obj)
        .subscribe(
          res => this.handleResponse(res),
          error => this.handleError(error),
        );
    } else {
      obj['clientes'] = clientes;
      this.id = this.wizardService.userData.infoBasica['cpfCnpjProcurador'];
      this.cadastroService
        .cadastrarProcurador(obj)
        .subscribe(
          res => this.handleResponse(res),
          error => this.handleError(error),
        );
    }
  }

  handleResponse(res) {
    this.loading = false;
    this.wizardService.resetData();
    this.wizardService.userData.prefeituras = this.prefeituras;
    this.wizardService.userData['codigo'] = res.resultado;
    this.wizardService.userData['id'] = this.id;
    this.wizardService.userData['perfil'] = this.perfil;
    if(this.atribuir === true){
      swal
      .fire(
        getSwal(
          "success",
          "Usuário cadastrado",
          "Foi adicionado o perfil Contribuinte ao seu cadastro. Use a mesma senha já cadastrada para ter acesso aos seus perfis de usuário.",
          "ok",
          null
        )
      )
      .then((res) => {
        this.router.navigate(["index"]);
      });
    } else{
      if(this.pessoa === "fisica"){
        this.router.navigate(['cadastro', this.perfil, 'codigo-fisica']);
      } else {
        this.router.navigate(['cadastro', this.perfil, 'codigo']);
      }
    }
      
  }

  handleError(error) {
    this.loading = false;
    this.wizardService.resetData();
    
    swal.fire(
      getSwal(
        'error',
        'Ops!',
        error.error.excecao.recomendacao,
        'ok',
        null,
      ),
    ).then(res => {
      this.router.navigate(['/sign']);
    });
  }

  desistir() {
    this.wizardService.resetData();
    this.router.navigate(['/index']);
  }

  voltar() {
    this.index = this.index - 1;
    // this.wizardService.updateIndex(this.index);
    this.location.back();
  }
}
